import (/* webpackPreload: true */ '@mdi/font/css/materialdesignicons.css');
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import light from './vuetify/theme'
Vue.use(Vuetify);

// import icon
import TakePhoto from '@/components/icon/TakePhoto.vue'
import Step from '@/components/icon/Step.vue'
Vuetify.config.silent = false

export default new Vuetify({
  theme: {
    themes: { 
      light: light
     },
  },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
        values: {
          takephoto: { // name of our custom icon
            component: TakePhoto, // our custom component
          },
          step: {
            component: Step
          },
          clear:'mdi-close-circle'
        },
      },
});
