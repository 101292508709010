import Vue from 'vue'
import './plugins/axios'
//import './plugins/axiospublic'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './i18n'
//import './plugins/dayjs'
import "@fontsource/kanit"
import VueOffline from 'vue-offline'
import VueGtag from "vue-gtag";
import Dayjs from 'vue-dayjs';
import 'dayjs/locale/th'
import firebase from 'firebase/app';
// import 'firebase/messaging';
import firebaseMessaging from './plugins/firebase'
import adapter from 'webrtc-adapter';

console.log(adapter.browserDetails.browser)
if (firebase.messaging.isSupported()) {
//   console.log('support')
Vue.prototype.$messaging = firebaseMessaging
Vue.prototype.$messaging.onMessage((payload) => {
   console.log('Message received. ', payload);
 });
 }




Vue.use(VueGtag, {
  config: { id: "UA-39887973-4" },
  appName: 'PWAStaging',
  pageTrackerScreenviewEnabled: true,
}, router);
Vue.use(Dayjs, {
  lang: 'th'
})

Vue.config.productionTip = false
Vue.use(VueOffline)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    this.$gtag.set({
      'cookie_flags': 'SameSite=None;Secure'
    })

    this.$gtag.exception({
      'description': 'error_description',
      'fatal': false
    })
  },
  render: h => h(App)
}).$mount('#app')
