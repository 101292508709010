import Vue from 'vue'
import VueRouter from 'vue-router'
import { VueOfflineStorage } from 'vue-offline'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "install" */ '../views/Install.vue'),
    meta: {
      authRequired: false
    },
  },
  {
    path: '/main',
    name: 'MainLayout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mainlayout" */ '../layouts/Main.vue'),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */'@/views/Home'),
        meta: {
          authRequired: true
        },
      },
      {
        path: 'addfriend',
        name: 'addfriend',
        component: () => import(/* webpackChunkName: "addfriend" */'@/views/AddFriend'),
        meta: {
          authRequired: true
        },
      },
      {
        path: 'inbox',
        name: 'inbox',
        component: () => import(/* webpackChunkName: "inbox" */'@/views/Inbox'),
        meta: {
          authRequired: true
        },
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import(/* webpackChunkName: "setting" */'@/views/Setting'),
        meta: {
          authRequired: true
        },
      }
    ]
  },
  {
    path: '/setting/lang',
    name: 'lang',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "lang" */'@/views/Lang'),
  },
  {
    path: '/inbox/message/',
    name: 'message',
    props: true,
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "message" */'@/views/Message'),
  },
  {
    path: '/formlist',
    name: 'formlist',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "form" */'@/views/FormList'),
  },
  {
    path: '/camera',
    name: 'camera',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "camera" */'@/views/Camera'),
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "onboarding" */'@/views/Onboarding'),
  }
  ,
  {
    path: '/user',
    name: 'user',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "onboarding" */'@/views/User'),
  },
  {
    path: '/form',
    name: 'form',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "form" */'@/layouts/Form'),
    children: [
      {
        path: 'info',
        name: 'info',
        props: true,
        component: () => import(/* webpackChunkName: "info" */'@/views/Info'),
        meta: {
          authRequired: true
        },
      },
      {
        path: 'inputform',
        name: 'inputform',
        props: true,
        component: () => import(/* webpackChunkName: "inputform" */'@/views/InputForm'),
        meta: {
          authRequired: true
        },
      },
      {
        path: 'complete',
        name: 'complete',
        component: () => import(/* webpackChunkName: "complete  " */'@/views/Complete'),
        meta: {
          authRequired: true
        },
      }
    ]
  },
  {
    path: '/form/editform',
    name: 'editform',
    props: true,
    component: () => import(/* webpackChunkName: "inputform" */'@/views/EditForm'),
    meta: {
      authRequired: true
    },
  },
  {
    path:'/recorddaily',
    name: 'recorddaily',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "recorddaily" */ '../layouts/RecordDaily.vue'),
    children:[
      {
        path:'waiting',
        name:'waiting',
        component: () => import(/* webpackChunkName: "waiting" */'@/views/Waiting')
      },
      {
        path:'done',
        name:'done',
        component: () => import(/* webpackChunkName: "done" */'@/views/Done')
      },
      {
        path:'savedraft',
        name:'savedraft',
        component: () => import(/* webpackChunkName: "savedraft" */'@/views/SaveDraft')
      }
    ]
  },
  {
    path: '/welcome',
    name: 'WelcomeLayout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "welcome" */ '../layouts/Welcome.vue'),
    meta: {
      authRequired: false
    },
    children: [
      {
        path: 'signin',
        name: 'signin',
        component: () => import(/* webpackChunkName: "signin" */'@/views/SignIn'),
        meta: {
          authRequired: false
        },
      },
      {
        path: 'otp/:mobile',
        name: 'otp',
        component: () => import(/* webpackChunkName: "otp" */'@/views/OTP'),
        props: true,
        meta: {
          authRequired: false
        },
      },
      {
        path: 'pn/:mode',
        props: true,
        name: 'pn',
        component: () => import(/* webpackChunkName: "pn" */'@/views/PN'),
        meta: {
          authRequired: false
        }
      },
      {
        path: 'tou/:mode',
        props: true,
        name: 'tou',
        component: () => import(/* webpackChunkName: "tou" */'@/views/TOU'),
        meta: {
          authRequired: false
        }
      },
      {
        path: 'tenant',
        name: 'selecttenant',
        component: () => import(/* webpackChunkName: "tenant" */'@/views/SelectTenant.vue'),
        meta: {
          authRequired: false
        },
      },
      {
        path: 'site',
        name: 'selectsite',
        component: () => import(/* webpackChunkName: "site" */'@/views/SelectSite.vue'),
        meta: {
          authRequired: false
        },
      }
    ],
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    redirect: '/main/home'
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }

})

const DEFAULT_TITLE = 'VWELL: สมาร์ท คัดกรอง'

router.beforeEach((to,form,next) => {
  let isAuthen = VueOfflineStorage.get('isAuthen')
  if (to.meta.authRequired && !isAuthen) next({ name: 'signin' })
  else next()
  document.title = to.meta.title || DEFAULT_TITLE
})

export default router
